import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  // Button,
  Dialog,
  IconButton,
  // Typography,
  // useMediaQuery,
  // useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";
import PanoramaFishEyeTwoToneIcon from "@mui/icons-material/PanoramaFishEyeTwoTone";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";

import { Pano, panos, ZoomPoint, orientation } from "./panoramas";
import Logo from "./images/Logo";

// import parkSound from "./audio/quiet-park-6781.mp3";
// import useSound from "use-sound";

const menuButtonStyles = {
  position: "absolute",
  zIndex: 10,
  "&>svg": {
    color: "rgba(255,255,255,0.8)",
    width: "3em",
    height: "3em",
  },
  "&:hover>svg": {
    color: "white",
  },
};

// const dimensionRatio =
//   Math.round((panoImageWidth / panoImageHeight) * 100) / 100;

export function PanoramaDisplay() {
  const navigate = useNavigate();
  const { id } = useParams();

  const panorama = panos.find((pano: Pano) => pano.id === id);
  // console.log("rendering pano: ", panorama);
  const [showZoomPoints, setShowZoomPoints] = useState(true);
  const [panoDisplayRatio, setPanoDisplayRatio] = useState(1);

  const panoRef = useRef(null);
  const panoScrollRef = useRef(null);
  const rootRef = useRef(null);

  // const [play, { stop }] = useSound(parkSound);

  useEffect(() => {
    updateDisplayRatio();
    window.addEventListener("resize", updateDisplayRatio);
    // setTimeout(() => centerPano(), 200);
    return () => {
      window.removeEventListener("resize", updateDisplayRatio);
      // stop(); // stop audio
    };
  }, []);

  const updateDisplayRatio = () => {
    const displayHeight = getPanoElement()?.clientHeight || 0;
    setPanoDisplayRatio(
      panorama?.height
        ? Math.round((displayHeight / panorama.height) * 100000) / 100000
        : 1,
    );
  };

  const getPanoElement = () => {
    return panoRef?.current ? (panoRef.current as HTMLElement) : null;
  };

  // const centerPano = () => {
  //   // getPanoElement()?.scrollTo({ left: Math.round(getMaxScoll() / 2) });
  //   const scrollDiv = panoScrollRef?.current
  //     ? (panoScrollRef.current as HTMLElement)
  //     : null;
  //   if (scrollDiv) {
  //     console.log(
  //       "getMaxScoll()",
  //       getMaxScoll(),
  //       Math.round(-getMaxScoll() / 4),
  //     );
  //     scrollDiv?.scrollTo({ left: Math.round(-getMaxScoll() / 4) });
  //     // scrollDiv?.scrollTo({ left: -getMaxScoll() });
  //     // scrollDiv?.scrollTo({ left: 600 });
  //   }
  // };
  //
  // const getMaxScoll = (): number => {
  //   const element = getPanoElement();
  //   if (element) {
  //     const boxHeight = element.clientHeight;
  //     const boxWidth = element.clientWidth;
  //     const width = boxHeight * panoDisplayRatio;
  //     return Math.round(width - boxWidth);
  //   } else {
  //     console.error("pano not found");
  //     return 0;
  //   }
  // };

  const handleFullScreenToggle = () => {
    if (!window?.document?.fullscreenElement && rootRef?.current) {
      // (rootRef.current as HTMLElement)?.requestFullscreen();
      if (window?.document?.body?.requestFullscreen) {
        window.document.body.requestFullscreen();
      }
    } else {
      window?.document?.exitFullscreen();
    }
  };

  const getZoomPoints = () => {
    return panorama?.zoomPoints?.map((zoomPoint: ZoomPoint) => {
      return (
        <Box
          component="a"
          key={zoomPoint.id}
          sx={{
            position: "absolute",
            cursor: "pointer",
            top: Math.round(zoomPoint.top * panoDisplayRatio),
            left: Math.round(zoomPoint.left * panoDisplayRatio),
            color: "white",
            "&>svg": {
              width: "2em",
              height: "2em",
              color: "rgba(255,255,255,0.8)",
            },
            "&:hover>svg": {
              color: "white",
            },
          }}
          onClick={() => handleZoom(zoomPoint)}
        >
          <PanoramaFishEyeTwoToneIcon />
        </Box>
      );
    });
  };

  const [zoomPoint, setZoomPoint] = useState<ZoomPoint | undefined>(undefined);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleZoom = (zoomPoint: ZoomPoint) => {
    setZoomPoint(zoomPoint);
  };

  const handleZoomClose = () => {
    setZoomPoint(undefined);
  };

  return panorama ? (
    <Box sx={{ height: "100vh", overflow: "hidden" }} ref={rootRef}>
      <Dialog
        // fullScreen={fullScreen}
        maxWidth={zoomPoint?.orientation === orientation.vertical ? "sm" : "lg"}
        open={!!zoomPoint}
        onClose={handleZoomClose}
        sx={{
          "& .MuiPaper-rounded": {
            borderRadius: 3,
            maxHeight: "calc(100% - 16px)",
            margin: 1,
          },
        }}
      >
        <IconButton
          sx={{ ...menuButtonStyles, top: 8, right: 8 }}
          aria-label="close"
          onClick={handleZoomClose}
        >
          <CloseIcon />
        </IconButton>
        <img src={zoomPoint?.src} alt={zoomPoint?.title || zoomPoint?.id} />
      </Dialog>
      <IconButton
        sx={{ ...menuButtonStyles, top: 0, right: 0 }}
        onClick={() => {
          setShowZoomPoints(!showZoomPoints);
        }}
      >
        {showZoomPoints ? <ScatterPlotOutlinedIcon /> : <ScatterPlotIcon />}
      </IconButton>
      <Box
        component="button"
        sx={{
          position: "absolute",
          zIndex: 10,
          top: 0,
          left: 0,
          minWidth: 160,
          background: "none",
          border: "none",
          p: "10px",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <Logo />
      </Box>
      {/*<IconButton*/}
      {/*  sx={{ ...menuButtonStyles, top: 0, right: 0 }}*/}
      {/*  onClick={() => {*/}
      {/*    navigate("/");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <CloseIcon />*/}
      {/*</IconButton>*/}
      <IconButton
        sx={{ ...menuButtonStyles, bottom: 10, right: 0 }}
        onClick={handleFullScreenToggle}
      >
        {window?.document?.fullscreenElement ? (
          <FullscreenExitIcon />
        ) : (
          <FullscreenIcon />
        )}
      </IconButton>
      <Box
        ref={panoScrollRef}
        sx={{
          width: "100vw",
          height: "100vh",
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        <Box
          ref={panoRef}
          sx={{
            position: "relative",
            width: Math.round(panorama.width * panoDisplayRatio),
            minHeight: "100vh",
            backgroundImage: `url(${panorama.pano})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              position: "relative",
              visibility: showZoomPoints ? "visible" : "hidden",
            }}
          >
            {getZoomPoints()}
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box>fallback text</Box>
  );
}
