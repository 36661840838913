import kateSessionsThumb from "./images/kate-sessions/kate-sessions-thumbnail.jpg";
import kateSessionsPano from "./images/kate-sessions/kate-sessions-pano.jpg";

import moleImage from "./images/kate-sessions/IMG_6648.jpg";
import treeImage from "./images/kate-sessions/IMG_6616.jpg";
import branchesImage from "./images/kate-sessions/IMG_6593.jpg";
import branchImage from "./images/kate-sessions/IMG_6581.jpg";
import grassImage from "./images/kate-sessions/IMG_6604.jpg";
import armedTreeImage from "./images/kate-sessions/IMG_6608.jpg";
import yellowFlowerImage from "./images/kate-sessions/IMG_6640.jpg";
import upTreeImage from "./images/kate-sessions/IMG_6631.jpg";
import cityImage from "./images/kate-sessions/IMG_6576.jpg";
import trafficImage from "./images/kate-sessions/IMG_6578.jpg";

import colesRoomThumb from "./images/coles-room/coles-room-thumb.jpg";
import colesRoomPano from "./images/coles-room/coles-room2sm.jpg";

import bookShelf from "./images/coles-room/IMG_6789.jpg";
import booksBed from "./images/coles-room/IMG_6793.jpg";
import blanket from "./images/coles-room/IMG_6791.jpg";
import namePainting from "./images/coles-room/IMG_6796.jpg";
import stuffies from "./images/coles-room/IMG_6797.jpg";
import solarDrawing from "./images/coles-room/IMG_6799.jpg";
import blobDrawing from "./images/coles-room/IMG_6800.jpg";
import peace from "./images/coles-room/IMG_6801.jpg";
import arnie from "./images/coles-room/IMG_6802.jpg";
import pokemonPosters from "./images/coles-room/IMG_6803.jpg";
import jakeShelf from "./images/coles-room/IMG_6805.jpg";
import anakKo from "./images/coles-room/IMG_6806.jpg";
import ninjago from "./images/coles-room/IMG_6807.jpg";
import booksUnder from "./images/coles-room/IMG_6811.jpg";

export type Pano = {
  id: string;
  title: string;
  path: string;
  thumbnail: string;
  pano: string;
  width: number;
  height: number;
  location?: string;
  zoomPoints?: ZoomPoint[];
};

export type ZoomPoint = {
  id: string;
  top: number;
  left: number;
  src: string;
  orientation: orientation;
  title?: string;
};

export enum orientation {
  vertical = "vertical",
  horizontal = "horizontal",
}

export const panos: Pano[] = [
  {
    id: "kate-sessions",
    path: "kate-sessions",
    thumbnail: kateSessionsThumb,
    pano: kateSessionsPano,
    title: "Kate Sessions Park",
    location: "Pacific Beach, San Diego, Ca",
    width: 10906,
    height: 4673,
    zoomPoints: [
      {
        id: "mole",
        title: "Mole",
        src: moleImage,
        top: 2488,
        left: 1506,
        orientation: orientation.horizontal,
      },
      {
        id: "tree",
        title: "Mole",
        src: treeImage,
        top: 2290,
        left: 2958,
        orientation: orientation.vertical,
      },
      {
        id: "branches",
        src: branchesImage,
        top: 1596,
        left: 3407,
        orientation: orientation.horizontal,
      },
      {
        id: "branch",
        src: branchImage,
        top: 2210,
        left: 5500,
        orientation: orientation.vertical,
      },
      {
        id: "grass",
        src: grassImage,
        top: 2820,
        left: 4126,
        orientation: orientation.horizontal,
      },
      {
        id: "armed-tree",
        src: armedTreeImage,
        top: 2494,
        left: 5419,
        orientation: orientation.vertical,
      },
      {
        id: "yellow-flower",
        src: yellowFlowerImage,
        top: 1686,
        left: 8117,
        orientation: orientation.horizontal,
      },
      {
        id: "up-tree",
        src: upTreeImage,
        top: 2831,
        left: 6579,
        orientation: orientation.vertical,
      },
      {
        id: "city",
        src: cityImage,
        top: 2339,
        left: 8486,
        orientation: orientation.vertical,
      },
      {
        id: "traffic",
        src: trafficImage,
        top: 2580,
        left: 9659,
        orientation: orientation.vertical,
      },
    ],
  },
  {
    id: "coles-room",
    path: "coles-room",
    thumbnail: colesRoomThumb,
    pano: colesRoomPano,
    title: "Cole's room",
    location: "Serra Mesa, San Diego, Ca",
    width: 21972,
    height: 4517,
    zoomPoints: [
      {
        id: "bookShelf",
        title: "Book Shelf",
        src: bookShelf,
        top: 2714,
        left: 9414,
        orientation: orientation.horizontal,
      },
      {
        id: "booksUnder",
        title: "Books Overflowing",
        src: booksUnder,
        top: 3221,
        left: 10436,
        orientation: orientation.horizontal,
      },
      {
        id: "stuffies",
        title: "Stuffies",
        src: stuffies,
        top: 2750,
        left: 11584,
        orientation: orientation.horizontal,
      },
      {
        id: "jakeShelf",
        title: "Jake Shelf",
        src: jakeShelf,
        top: 1215,
        left: 11849,
        orientation: orientation.horizontal,
      },
      {
        id: "booksBed",
        title: "Books for morning reading",
        src: booksBed,
        top: 2818,
        left: 12333,
        orientation: orientation.horizontal,
      },
      {
        id: "blanket",
        title: "Blanket crocheted by Mama",
        src: blanket,
        top: 2605,
        left: 15789,
        orientation: orientation.horizontal,
      },
      {
        id: "anakKo",
        title: 'Anak Ko ("My Child")',
        src: anakKo,
        top: 462,
        left: 13777,
        orientation: orientation.horizontal,
      },
      {
        id: "pokemonPosters",
        title: "Pokemon Posters",
        src: pokemonPosters,
        top: 1043,
        left: 15928,
        orientation: orientation.horizontal,
      },
      {
        id: "namePainting",
        title: "Name Painting",
        src: namePainting,
        top: 1803,
        left: 2433,
        orientation: orientation.horizontal,
      },
      {
        id: "blobDrawing",
        title: "Blob drawing",
        src: blobDrawing,
        top: 1327,
        left: 17348,
        orientation: orientation.horizontal,
      },
      {
        id: "peace",
        title: "Peace Painting",
        src: peace,
        top: 1382,
        left: 17993,
        orientation: orientation.horizontal,
      },
      {
        id: "solarDrawing",
        title: "Our Solar System Drawing",
        src: solarDrawing,
        top: 1936,
        left: 17935,
        orientation: orientation.horizontal,
      },
      {
        id: "arnie",
        title: "Arnie",
        src: arnie,
        top: 1794,
        left: 17334,
        orientation: orientation.horizontal,
      },
      {
        id: "ninjago",
        title: "Ninjago",
        src: ninjago,
        top: 2246,
        left: 9192,
        orientation: orientation.horizontal,
      },
    ],
  },
];
