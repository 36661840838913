import React from "react";
import { Box, Typography } from "@mui/material";
import Logo from "./images/Logo";

export const Title = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "black",
        p: 2,
        position: "fixed",
        width: "100%",
        minHeight: 66,
        top: 0,
      }}
    >
      <Box sx={{ minWidth: 160 }}>
        <Logo title="Immersive Panorama" />
      </Box>

      <Typography
        variant="h1"
        sx={{
          color: "white",
          ml: "1rem",
          fontSize: {
            xs: "2rem",
            sm: "2rem",
            md: "3rem",
          },
          // ml: {
          //   xs: 1,
          //   sm: 1,
          //   lg: 2,
          // },
        }}
      >
        Immersive Panorama
      </Typography>
    </Box>
  );
};
