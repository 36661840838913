import React from "react";
import { Box } from "@mui/material";
import { Title } from "./Title";
import { Pano, panos } from "./panoramas";

export const List = () => {
  return (
    <Box>
      <Title />
      <Box
        sx={{
          mt: "100px",
          p: 2,
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          flexWrap: "wrap",
        }}
      >
        {panos.map((pano: Pano) => (
          <Box
            key={pano.id}
            component="a"
            sx={{
              backgroundImage: `url(${pano.thumbnail})`,
              border: "none",
              borderRadius: 1,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "center",
              minHeight: 80,
              minWidth: {
                xs: "auto",
                sm: "auto",
                md: 500,
              },
              textAlign: "center",
              textShadow: "1px 1px 5px black",
              textDecoration: "none",
              color: "white",
              fontSize: "2rem",
              px: 3,
              py: 4,
              mr: {
                xs: 0,
                sm: 0,
                md: 2,
              },
              mb: 2,
            }}
            href={`./panorama/${pano.path}`}
          >
            {pano.title}
            {pano.location ? (
              <span>
                ,<br /> {pano.location}
              </span>
            ) : (
              ""
            )}
          </Box>
        ))}
      </Box>
      {/*<Link to={`./panorama/${pano.path}`}>*/}
      {/*  <img*/}
      {/*    src={pano.thumbnail}*/}
      {/*    alt={pano.title}*/}
      {/*    style={{*/}
      {/*      borderRadius: 8,*/}
      {/*      width: 80,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Link>*/}
      {/*<Typography*/}
      {/*  variant="h4"*/}
      {/*  sx={{*/}
      {/*    ml: 2,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Link to={`./panorama/${pano.path}`}>*/}
      {/*    {pano.title}*/}
      {/*    {pano.location ? `, ${pano.location}` : ""}*/}
      {/*  </Link>*/}
      {/*</Typography>*/}
    </Box>
  );
};
